import React from 'react';
import Head from 'next/head';


function MyNewsMetadata() {
  return (
    <>
      <Head>
        <title>NBC News - My News</title>
        <meta property="og:title" content="NBC News - My News" key="title" />
      </Head>
    </>
  );
}

export default MyNewsMetadata;
