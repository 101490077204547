import React from 'react';
import { buildCollectionPage } from 'lib/LDJson';
import Head from 'next/head';

function SavingsMetadata() {
  const jsonLdPageType = 'SectionFront';
  const seoDescription = 'Shop TODAY savings finds the best discounts from over 40,000 online stores so you never have to search for deals. Download the shopping extension for exclusive promo codes.';
  const seoTitle = 'Shop TODAY Savings: Deals and coupon shopping extension | TODAY';
  const url = 'https://www.today.com/savings';
  const vertical = 'today';
  const publisherId = 'https://www.today.com/#publisher';

  const collectionPageJson = buildCollectionPage({
    jsonLdPageType,
    description: seoDescription,
    title: seoTitle,
    url,
    vertical,
  }, vertical, publisherId);

  return (
    <Head>
      <title>{seoTitle}</title>
      <link rel="canonical" href="https://www.today.com/savings" />
      <meta name="description" content={seoDescription} />
      <script
        type="application/ld+json"
        data-test="front_CollectionPage"
        dangerouslySetInnerHTML={{ // eslint-disable-line react/no-danger
          __html: `${JSON.stringify(collectionPageJson)}`,
        }}
      />
    </Head>
  );
}

export {
  SavingsMetadata,
};

