const isString = (value) => typeof value === 'string';

const decodeString = (string) => {
  try {
    if (isString(string)) {
      return decodeURIComponent(string);
    }
  } catch (e) {
    // no-op; if any error threw above, the original assignment shouldn't have been overwritten
  }
  return string;
};

/**
* Truncate a string to a specified length
* @param {string} string
* @param {number} len
* @returns {string}
*/
const truncateString = (string, len) => {
  if (typeof string === 'string') {
    return string.substring(0, len);
  }
  return '';
};

const generateElementKey = (value, length = 20) => {
  if (isString(value)) {
    return value.replace(/\s+/g, '').substring(0, length);
  }
  return value;
};

const replaceAmpersands = (value) => {
  if (isString(value)) {
    return value.replace(/&amp;/g, '&');
  }
  return value;
};

/**
 * Prepend a string with a prefix separated by a delimiter if valid values are provided
 * @param {string} prefix
 * @param {string} string
 * @param {string} delimiter
 * @returns {string}
 */
function prependString(prefix, string, delimiter = ' | ') {
  if (isString(prefix)) {
    return [prefix, string].join(delimiter);
  }
  return string;
}

export {
  truncateString,
  decodeString,
  generateElementKey,
  replaceAmpersands,
  prependString,
};
