/* eslint-disable max-len */
import React, { useContext } from 'react';
import Head from 'next/head';

import {
  PersonContext,
  RouteContext,
  VerticalContext,
} from 'lib/ContextTypes';
import { buildAuthor, buildProfilePage } from 'lib/LDJson';
import { shareImage } from 'lib/social';
import { getDisplayNameForVertical } from 'lib/vertical';

function PersonMetadata() {
  /** @type {PersonContext} */
  const person = useContext(PersonContext);
  const route = useContext(RouteContext);
  const vertical = useContext(VerticalContext);

  const verticalDisplayName = getDisplayNameForVertical(vertical);

  // {vertical} Author {name}, {vertical} Writer {name}
  const title = `${verticalDisplayName} Author ${person.name}`;

  const description = `Read more about professional author ${person.name}. \
    View the writer's qualifications and read articles written by author ${person.name} at ${verticalDisplayName}.`;

  const imageUrl = person?.primaryImage?.url?.primary;
  const canonical = person?.url?.canonical ?? null;

  const host = `https://www.${route.domain}`;
  const ldJsonProfileObject = buildProfilePage(person, vertical, host);

  return (
    <Head>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="thumbnail" content={shareImage(imageUrl, vertical)} />

      <meta property="og:url" content={`${host}${route.path}`} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={shareImage(imageUrl, vertical, 'og')} />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />
      <meta property="og:type" content="website" />

      <meta name="twitter:image" content={shareImage(imageUrl, vertical, 'twitter')} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:card" content="summary_large_image" />

      <link rel="canonical" href={canonical} />

      <script
        type="application/ld+json"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: `${JSON.stringify(ldJsonProfileObject)}`,
        }}
      />

      <script
        type="application/ld+json"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: `${JSON.stringify(buildAuthor(person, vertical))}`,
        }}
      />
    </Head>
  );
}

export { PersonMetadata };
