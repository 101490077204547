import React from 'react';
import PropTypes from 'prop-types';
import Head from 'next/head';


import {
  buildRecipeDataset,
  buildRecipe,
} from 'lib/LDJson';
import { recipe as recipePropType } from 'lib/CustomPropTypes';
import { shareImage } from 'lib/social';
import { getCanonical } from 'lib/urlUtils';

import AMPHTMLLink from 'components/AMP/HTMLLink';
import { resolveOnTheShowMetadata } from './metadataUtils';

function constructMetaData(recipe, vertical) {
  const {
    dateModified,
    datePublished,
    description,
    headline,
    hidden,
    nativeAd,
    primaryImage,
    socialImage,
    teaseImage,
    url,
  } = recipe;

  const image = socialImage || teaseImage || primaryImage || {};
  const imageUrl = (image.url?.primary);

  const onTheShowMetadata = resolveOnTheShowMetadata(recipe.taxonomy, vertical);

  return (
    <Head>
      <title>{headline.seo || headline.primary}</title>
      <meta
        name="description"
        content={description.seo || description.primary}
      />
      <link rel="canonical" href={getCanonical(url)} />
      {(nativeAd || hidden) && (
        <meta name="robots" content="noindex, nofollow" />
      )}

      <meta property="og:url" content={url.primary} />
      <meta property="og:title" content={headline.social || headline.primary} />
      <meta
        property="og:description"
        content={description.social || description.primary || description.seo}
      />
      <meta
        property="og:image"
        content={shareImage(imageUrl, vertical, 'og')}
      />
      <meta
        property="og:image:secure_url"
        content={shareImage(imageUrl, vertical, 'og')}
      />
      <meta
        property="og:image:type"
        content={image.encodingFormat || 'image/gif'}
      />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />
      <meta property="og:type" content="recipe" />

      <meta
        name="twitter:image:src"
        content={shareImage(imageUrl, vertical, 'twitter')}
      />
      <meta
        name="twitter:image"
        content={shareImage(imageUrl, vertical, 'twitter')}
      />
      <meta
        name="twitter:title"
        content={headline.social || headline.primary}
      />
      <meta
        name="twitter:description"
        content={description.social || description.primary || description.seo}
      />
      <meta name="twitter:card" content="summary_large_image" />

      <meta property="recipe:published" content={datePublished} />
      <meta property="recipe:modified" content={dateModified} />

      <meta name="thumbnail" content={shareImage(imageUrl, vertical)} />

      {onTheShowMetadata}
    </Head>
  );
}

function RecipeMetadata({ recipe, vertical }) {
  return (
    <>
      <Head>
        <script
          type="application/ld+json"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: `${JSON.stringify(buildRecipeDataset(recipe, vertical))}`,
          }}
        />
        <script
          type="application/ld+json"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: `${JSON.stringify(buildRecipe(recipe, vertical))}`,
          }}
        />
      </Head>

      {constructMetaData(recipe, vertical)}

      <AMPHTMLLink content={recipe} vertical={vertical} />
    </>
  );
}

RecipeMetadata.propTypes = {
  recipe: recipePropType.isRequired,
  vertical: PropTypes.string.isRequired,
};

export { RecipeMetadata };
