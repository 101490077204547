import React from 'react';

import { getFeatureConfigForBrand } from 'lib/getFeatureStatus';
import { QUERYLY_SEARCH_METADATA } from 'lib/brandFeatures';
import { PLAYMAKER_STREAM_JSON_LD_METADATA } from 'lib/playmaker';
import { buildLiveVideoObjectLD, buildFAQ } from 'lib/LDJson';
import { getPublisher } from 'lib/headerMetadata';

// TODAY specific taxonomy path for "On the Show" content
const ON_THE_SHOW_TAX_PATH = 'today/label/on-the-show';
// Video file names begin with "tdy_" if they are related to the show;
// most videos are "On the Show" content, and there isn't a workflow to mark them with the taxonomy.
// Video file names starting with "x_tdy_" are NOT Show content
const VIDEO_FILENAME_PREFIX = 'tdy_';

const PRINCIPAL_SHOWS = [
  '3rd Hour of TODAY',
  'TODAY with Hoda & Jenna',
  'Weekend TODAY',
  'Sunday TODAY',
];
/**
 * Adds custom meta tags on the page if content is tagged with Show related taxonomy
 * Used by Queryly to crawl site content and create category filters
 * @param {object} taxonomy main taxonomy object returned from the API
 * @param {array[TaxonomyPropType]} [taxonomy.shows] an array of taxonomy terms (shape defined in lib/CustomPropTypes/taxonomy)
 * @param {array[TaxonomyPropType]} [taxonomy.labels] an array of taxonomy terms (shape defined in lib/CustomPropTypes/taxonomy)
 * @param {array[TaxonomyPropType]} [taxonomy.additionalTerms] an array of taxonomy terms (shape defined in lib/CustomPropTypes/taxonomy)
 * @param {string} vertical used to restrict this function only to relevant brands
 * @param {string} [videoFileName] the file name of the current video; used in canonical video pages
 * @returns {React.ReactElement}
 */
const resolveOnTheShowMetadata = (taxonomy = {}, vertical = null, videoFileName = null) => {
  if (!taxonomy) return null;
  const { shows, labels, additionalTerms } = taxonomy;
  const hasShowMetadata = getFeatureConfigForBrand(QUERYLY_SEARCH_METADATA, vertical);

  if (!hasShowMetadata) return null;

  let isOnTheShow = false;
  // create a Set to add show names
  // to avoid duplicates since the same Show taxonomy can appear in multiple areas
  const showNamesSet = new Set();

  // go through 'shows' taxonomy to check for a Show
  if (Array.isArray(shows)) {
    shows.forEach((show) => {
      if (show.taxonomyType === 'show') {
        showNamesSet.add(show.name);
      }
    });
  }

  if (videoFileName) {
    // if video content, check file name to see if it is 'On the Show' content
    isOnTheShow = videoFileName.startsWith(VIDEO_FILENAME_PREFIX);
  } else if (Array.isArray(labels)) {
    // otherwise, go through 'labels' taxonomy to check for 'On the Show' label
    isOnTheShow = labels.some(({ path }) => path === ON_THE_SHOW_TAX_PATH);
  }

  // 'On the Show' taxonomy label and 'show' taxonomy types can also appear under additionalTerms taxonomy
  // so go through that list to check for both
  if (Array.isArray(additionalTerms)) {
    additionalTerms.forEach((term) => {
      if (term.path === ON_THE_SHOW_TAX_PATH) {
        isOnTheShow = true;
      }
      if (term.taxonomyType === 'show') {
        showNamesSet.add(term.name);
      }
    });
  }

  const showNames = [...showNamesSet];

  const showNamesAvailable = !!showNames.length;
  return ((isOnTheShow || showNamesAvailable) ? (
    <>
      {/* meta tag to be added if content is tagged with "On the Show" taxonomy label */}
      { isOnTheShow && <meta name="additional_type" content="show" /> }
      {/* meta tag to be added if content is tagged as a Show taxonomy type */}
      {showNames.map((name) => {
        const content = PRINCIPAL_SHOWS.includes(name) ? name : `${name}|TODAY`;
        return <meta key={name.replace(/\/s/i, '-')} name="shows" content={content} />;
      })}
      {(isOnTheShow && !showNamesAvailable) && <meta name="shows" content="TODAY" />}
    </>
  ) : null);
};


/**
 * Function for creating BroadcastEvent structured data for live videos in live blogs and articles
 */

const useBroadcastEvent = ({ primaryMedia }) => {
  // InlineVideo/LiveBlog component (used for NDP videos) already adds its own JSON LD so we only need to add
  // it here for playmaker linear streams
  const isPlaymakerLiveVideo = primaryMedia?.type === 'embeddedPlaymakerLiveVideo';
  const liveVideoJsonLdMetadata = isPlaymakerLiveVideo
    && PLAYMAKER_STREAM_JSON_LD_METADATA[primaryMedia.playmakerLiveVideo?.playmakerId];

  return (
    <>
      {isPlaymakerLiveVideo && (
        <script
          data-test="playmaker-live-video-ld-json"
          type="application/ld+json"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(buildLiveVideoObjectLD(liveVideoJsonLdMetadata)),
          }}
        />
      )}
    </>
  );
};

/**
 * Function for creating FAQs structured data for articles
 * @param {Object} article
 *
 */
const useFAQ = (article) => {
  const faqs = buildFAQ(article);
  // checking if FAQ exists in article data, then return this structured data
  const isFAQ = Object.keys(faqs).length > 0;

  return (
    <>
      {isFAQ && (
        <script
          data-test="faq-ld-json"
          type="application/ld+json"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: JSON.stringify(faqs) }}
        />
      )}
    </>
  );
};

/**
 * Function to get publisher object for WebSite structured data.
 * If the vertical is 'news', 'msnbc', or 'select', it will use the vertical publisher configuration,
 * else it will use the organization publisher configuration.
 * @param {Object} params - The parameters to build the publisher object.
 * @param {string} params.vertical The vertical for the page.
 * @param {string} params.organization The parent organization of the vertical.
 * @param {string} params.publisherLogo The logo URL for the publisher.
 */
export const getWebSitePublisher = ({ vertical = '', organization, publisherLogo }) => {
  const useVerticalPublisherJsonLD = ['news', 'msnbc', 'select'].includes(vertical) || !organization;
  if (useVerticalPublisherJsonLD) {
    return getPublisher(vertical);
  }
  return {
    '@type': 'NewsMediaOrganization',
    name: organization,
    logo: publisherLogo ? {
      '@type': 'ImageObject',
      url: publisherLogo,
    } : '',
  };
};

export { resolveOnTheShowMetadata, useBroadcastEvent, useFAQ };
