import React from 'react';
import PropTypes from 'prop-types';
import Head from 'next/head';

import { slideshow as slideshowPropType } from 'lib/CustomPropTypes';
import { buildGallery, buildImageGallery } from 'lib/LDJson';
import { shareImage } from 'lib/social';
import { getCanonical } from 'lib/urlUtils';
import { resolveOnTheShowMetadata } from './metadataUtils';

function constructMetaData(slideshow, vertical) {
  const {
    description, headline, hidden, teaseImage, primaryImage, url,
  } = slideshow;

  const mainImage = primaryImage || teaseImage;
  const imageUrl = mainImage.url?.primary;

  const onTheShowMetadata = resolveOnTheShowMetadata(slideshow.taxonomy, vertical);

  return (
    <Head>
      <title>{headline.seo || headline.primary}</title>
      <meta name="description" content={description.primary} />
      <link rel="canonical" href={getCanonical(url)} />

      {hidden && <meta name="robots" content="NOINDEX, NOFOLLOW" />}
      <meta property="og:url" content={url.primary} />
      <meta property="og:title" content={headline.social || headline.primary} />
      <meta
        property="og:description"
        content={description.social || description.primary || description.seo}
      />
      <meta
        property="og:image"
        content={shareImage(imageUrl, vertical, 'og')}
      />
      <meta
        property="og:image:secure_url"
        content={shareImage(imageUrl, vertical, 'og')}
      />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />
      <meta property="og:type" content="article" />

      <meta
        name="twitter:image"
        content={shareImage(imageUrl, vertical, 'twitter')}
      />
      <meta
        name="twitter:image:src"
        content={shareImage(imageUrl, vertical, 'twitter')}
      />
      <meta
        name="twitter:title"
        content={headline.social || headline.primary}
      />
      <meta
        name="twitter:description"
        content={description.social || description.primary || description.seo}
      />
      <meta name="twitter:card" content="summary_large_image" />

      <meta name="thumbnail" content={shareImage(imageUrl, vertical)} />

      {onTheShowMetadata}
    </Head>
  );
}

function SlideshowMetadata({ slideshow, vertical }) {
  return (
    <>
      <Head>
        <script
          type="application/ld+json"
          data-testId="slideshow__ld-json-gallery"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: `${JSON.stringify(buildGallery(slideshow, vertical))}`,
          }}
        />
        <script
          type="application/ld+json"
          data-testId="slideshow__ld-json-image-gallery"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: `${JSON.stringify(buildImageGallery(slideshow, vertical))}`,
          }}
        />
      </Head>

      {constructMetaData(slideshow, vertical)}
    </>
  );
}

SlideshowMetadata.propTypes = {
  slideshow: slideshowPropType.isRequired,
  vertical: PropTypes.string.isRequired,
};

export { SlideshowMetadata };
