import React from 'react';
import PropTypes from 'prop-types';
import Head from 'next/head';
import getConfig from 'next/config';
import get from 'lodash.get';

import {
  curation as curationPropType,
  package as packagePropType,
} from 'lib/CustomPropTypes';
import { shareImage } from 'lib/social';
import { prependString } from 'lib/stringUtils';
import { getCanonical } from 'lib/urlUtils';
import getISODate from 'lib/getISODate';
import {
  isNewsVertical,
  isTelemundoVertical,
} from 'lib/vertical';
import { buildRecipeCarouselFromFront, buildCollectionPage, buildProductBestsellersFromFront } from 'lib/LDJson';
import { getWebSitePublisherLogo } from 'lib/LDJson/getWebSitePublisherLogo';
import { getFeatureConfigForBrand } from 'lib/getFeatureStatus';
import { GATED_CONTENT } from 'lib/brandFeatures';
import { useFeatureFlagContext } from 'lib/ContextTypes';
import { getWebSitePublisher } from './metadataUtils';

const constructJSONLD = ({
  url = '',
  section = '',
  pageType = '',
  vertical = '',
  id = '',
  isContentGated = false,
}) => JSON.stringify({
  '@context': {
    '@vocab': 'http://schema.org',
    pageType: {
      '@id': 'Text',
      '@type': '@id',
    },
    vertical: {
      '@id': 'Text',
      '@type': '@id',
    },
    subVertical: {
      '@id': 'Text',
      '@type': '@id',
    },
    section: {
      '@id': 'Text',
      '@type': '@id',
    },
    subSection: {
      '@id': 'Text',
      '@type': '@id',
    },
    packageId: {
      '@id': 'Text',
      '@type': '@id',
    },
    sponsor: {
      '@id': 'Text',
      '@type': '@id',
    },
    appVersion: {
      '@id': 'Text',
      '@type': '@id',
    },
    gatedContentEnabled: {
      '@id': 'Text',
      '@type': '@id',
    },
  },
  '@type': 'Dataset',
  name: 'additionalTaxonomy',
  description: 'This is additional taxonomy that helps us with analytics',
  url,
  pageType,
  vertical,
  subVertical: section || '', // subvertical is always the section on the front (but not in content)
  section: '',
  subSection: '',
  packageId: id,
  sponsor: '', // no sponsored fronts I think
  appVersion: get(getConfig(), ['publicRuntimeConfig', 'appVersion'], ''),
  gatedContentEnabled: isContentGated,
});

const getMetadataValue = (metadata) => (field) => {
  const row = metadata.find((data) => data.field === field);
  return get(row, 'source.value');
};

function FrontMetadata(props) {
  const {
    curation,
    domain,
    firstContentInZoneA,
    section,
    vertical,
  } = props;

  const {
    curationMetadata = [],
    dateCreated,
    dateModified,
    hidden,
    id,
    pageMetadata = [],
    pageType,
    title,
    pageRoute,
  } = curation;

  const getPageMetadata = getMetadataValue(pageMetadata);
  const getCurationMetadata = getMetadataValue(curationMetadata);

  const seoTitle = getPageMetadata('seoTitle');
  const seoDescription = getPageMetadata('seoDescription');

  let socialDescription = getCurationMetadata('socialDescription');
  if (typeof socialDescription !== 'string' || !socialDescription.length) {
    socialDescription = seoDescription;
  }

  let socialTitle = getCurationMetadata('socialTitle');
  if (typeof socialTitle !== 'string' || !socialTitle.length) {
    socialTitle = seoTitle || title;
  }

  // Share and social images
  let shareImageURL = null;
  const defaultImageURL = getPageMetadata('socialImageUrl');
  if (defaultImageURL) {
    shareImageURL = defaultImageURL;
  }
  const overrideImageURL = getCurationMetadata('socialImageUrl');
  if (overrideImageURL) {
    shareImageURL = overrideImageURL;
  }
  const shareImageThumb = shareImage(shareImageURL, vertical);
  const shareImageOG = shareImage(shareImageURL, vertical, 'og');
  const shareImageTwitter = shareImage(shareImageURL, vertical, 'twitter');

  let titleValue = seoTitle; // title tag
  let twitterSite;
  let organization;
  let publisherLogo;

  if (vertical === 'msnbc') {
    organization = 'MSNBC';
    twitterSite = 'MSNBC';
  } else if (vertical === 'today') {
    organization = 'TODAY';
    titleValue = prependString(seoTitle, organization);
    twitterSite = 'TodayShow';
  } else if (isTelemundoVertical(vertical)) {
    organization = 'Telemundo';
    titleValue = prependString(seoTitle, organization);
    twitterSite = 'Telemundo';
  } else if (isNewsVertical(vertical)) {
    organization = 'NBC News';
    titleValue = prependString(seoTitle, organization);
    twitterSite = 'NBCNews';
    publisherLogo = getWebSitePublisherLogo();
  }

  let url = `https://www.${domain}`;
  if (['news', 'msnbc', 'today', 'sponsoredcontent'].includes(vertical) === false) {
    url += `/${vertical}`;
  }
  if (section) {
    url += `/${section}`;
  }

  const isShowLead = firstContentInZoneA
    && firstContentInZoneA.type === 'showLead'
    && firstContentInZoneA.metadata;

  const hasGatedContentFeature = getFeatureConfigForBrand(
    GATED_CONTENT,
    vertical,
  );
  const launchDarklyFlags = useFeatureFlagContext();
  const gateFrontPageFlag = launchDarklyFlags?.['today-gated-front'];

  const isContentGated = (pageRoute && ((pageRoute === '/start-today' && gateFrontPageFlag) || pageRoute === '/account')
      && hasGatedContentFeature);

  const showDescription = isShowLead ? firstContentInZoneA.metadata.description : null;

  const jsonLdPageType = (() => {
    if (pageType === 'showPage') return pageType;

    return section ? 'SectionFront' : 'Front'; // need lowercase later, and try to not be hardcoded
  })();

  const recipeCarousel = buildRecipeCarouselFromFront(curation, vertical);
  const productBestsellers = buildProductBestsellersFromFront(curation);
  const publisherId = `https://www.${domain}/#publisher`;

  // NGCOMRC-249 fix misleading mobile search result
  const excludeRecipeLists = section === 'shop/mothers-day-plaza';

  const collectionPageJson = buildCollectionPage({
    jsonLdPageType,
    description: seoDescription,
    title: seoTitle,
    url,
    vertical,
  }, vertical, publisherId);

  return (
    <Head>
      <title>{titleValue}</title>
      <meta name="description" content={seoDescription} />
      <meta name="thumbnail" content={shareImageThumb} />

      {vertical === 'news' && !section && (
        <>
          <meta
            name="pocket-site-verification"
            content="7d430daabab89c549b078097430be5"
          />
          <meta
            name="google-site-verification"
            content="xcFg25oVUcM7Zmt3RO2DdNXUrzie1AqgHOoKA_uTeks"
          />
        </>
      )}
      {vertical === 'today' && !section && (
        <meta
          name="google-site-verification"
          content="CW4UdAimFXD75AQd4jFiVmY4R-gOduNQJHSJMfzr8cw"
        />
      )}
      {vertical === 'msnbc' && !section && (
        <meta
          name="google-site-verification"
          content="REPjLi_yDHx7TLKgH3OC5uFnLfGp1M-1m8QUec6g8qQ"
        />
      )}

      <link rel="canonical" href={getCanonical(url)} />

      <meta property="og:url" content={url} />
      <meta property="og:title" content={socialTitle} />
      <meta property="og:description" content={socialDescription} />
      <meta property="og:image" content={shareImageOG} />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />
      <meta property="og:type" content="website" />

      <meta name="twitter:site" content={twitterSite} />
      <meta name="twitter:title" content={socialTitle} />
      <meta name="twitter:description" content={socialDescription} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:image" content={shareImageTwitter} />
      <meta name="twitter:image:src" content={shareImageTwitter} />

      {/* Added Collection Pages to all section pages */}
      <script
        type="application/ld+json"
        data-testid="front_CollectionPage"
        dangerouslySetInnerHTML={{ // eslint-disable-line react/no-danger
          __html: `${JSON.stringify(collectionPageJson)}`,
        }}
      />
      <script
        type="application/ld+json"
        data-testid="front-jsonld"
        dangerouslySetInnerHTML={{ // eslint-disable-line react/no-danger
          __html: constructJSONLD({
            url, section, vertical, id, pageType: jsonLdPageType, isContentGated,
          }),
        }}
      />
      {pageType !== 'showPage' ? (
        <script
          type="application/ld+json"
          data-testid="jsonld__WebSite"
          dangerouslySetInnerHTML={{ // eslint-disable-line react/no-danger
            __html: JSON.stringify(
              {
                '@context': 'http://schema.org',
                '@type': 'WebSite',
                url,
                identifier: { // not sure this should be included
                  '@type': 'PropertyValue',
                  propertyID: 'Document Id',
                  value: id,
                },
                headline: title,
                alternativeHeadline: seoTitle,
                dateCreated: getISODate(dateCreated),
                datePublished: getISODate(dateCreated),
                dateModified: getISODate(dateModified),
                potentialAction: {
                  '@type': 'SearchAction',
                  target: `https://www.${domain}/search?q={search_term_string}`,
                  'query-input': 'required name=search_term_string',
                },
                image: shareImageURL ? {
                  '@type': 'ImageObject',
                  url: shareImageURL,
                } : '',
                publisher: getWebSitePublisher({ vertical, organization, publisherLogo }),
                description: seoDescription,
                mainEntityOfPage: {
                  '@type': 'WebPage',
                  inLanguage: 'English',
                  datePublished: dateCreated,
                  name: domain,
                  '@id': url,
                },
              },
            ),
          }}
        />
      ) : (
        <script
          type="application/ld+json"
          data-testid="jsonld__TVSeries"
          dangerouslySetInnerHTML={{ // eslint-disable-line react/no-danger
            __html: JSON.stringify(
              {
                '@context': 'http://schema.org',
                '@type': 'TVSeries',
                url,
                productionCompany: vertical,
                temporalCoverage: showDescription,
                description: seoDescription,
                thumbnailUrl: shareImageThumb,
              },
            ),
          }}
        />
      )}

      {productBestsellers && (
        <script
          type="application/ld+json"
          data-testid="jsonld__ProductBestsellers"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: `${JSON.stringify(productBestsellers)}` }}
        />
      )}
      {recipeCarousel && !excludeRecipeLists && (
        <script
          type="application/ld+json"
          data-testid="jsonld__RecipeCarousel"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: `${JSON.stringify(recipeCarousel)}` }}
        />
      )}

      {hidden && (
        <meta name="robots" content="NOINDEX, NOFOLLOW" data-testid="front__robots" />
      )}
    </Head>
  );
}

FrontMetadata.propTypes = {
  curation: curationPropType.isRequired,
  domain: PropTypes.string.isRequired,
  firstContentInZoneA: packagePropType.isRequired,
  section: PropTypes.string.isRequired,
  vertical: PropTypes.string.isRequired,
};

export {
  constructJSONLD,
  FrontMetadata,
};
