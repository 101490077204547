import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Head from 'next/head';
import get from 'lodash.get';

import { isShellArticle } from 'lib/article';
import { AMPHTML_VIDEO_LINK, SHOW_BLOGS as ShowBlogConfig } from 'lib/brandFeatures';
import { shouldRenderRegularBlog } from 'lib/liveBlog';
import { getFeatureConfigForBrand } from 'lib/getFeatureStatus';

const formatVertical = (vertical) => vertical;

const detectShowBlogAmpArticleSubPath = ({ vertical, currentPath }) => {
  try {
    const [showName, blogPath] = currentPath.replace(/^\//, '').split('/');
    const show = ShowBlogConfig[vertical][`/${showName}/${blogPath}/`];
    return show?.ampArticleSubPath || '';
  } catch (error) {
    console.warn('Unknown Show Blog AMP ⚡ path.');
    return ''; // defaults to regular article AMP route
  }
};

export const buildAMPHTMLLink = ({
  content,
  currentPath,
  domain,
  isLiveBlog,
  isShowBlog,
  vertical,
}) => {
  const id = get(content, 'id', null);

  // Return null if content ID does not start with n, t, r, m or w or vertical is deportes
  // (like - ncna##, tdna##, rcna##, mmvo##, wbna##)
  if (!domain || !vertical || !id || !/^[ntrmw]/.test(id) || vertical === 'deportes') {
    return null;
  }

  // handles video page routing for msnbc, news, and today
  let amp = 'amp';
  if (content.type === 'video') {
    const showAmpVideoLink = getFeatureConfigForBrand(AMPHTML_VIDEO_LINK, vertical);
    if (showAmpVideoLink) {
      amp = 'amp-video';
    } else {
      return null;
    }
  }

  const nativeAd = get(content, 'nativeAd', false);

  if (nativeAd || isShellArticle(content)) {
    return null;
  }

  let subPath = '';
  if (isLiveBlog) {
    const subType = get(content, 'subType', null);
    subPath = shouldRenderRegularBlog(subType) ? 'blog/' : 'live-blog/';
  } else if (isShowBlog) {
    subPath = detectShowBlogAmpArticleSubPath({ vertical, currentPath });
  } else if (content.type === 'recipe') {
    subPath = 'recipes/';
  }

  return `https://www.${domain}/${formatVertical(vertical)}/${amp}/${subPath}${id}`;
};

const AMPHTMLLink = (props) => {
  const {
    content,
    currentPath,
    domain,
    isLiveBlog,
    isShowBlog,
    vertical,
  } = props;

  const link = buildAMPHTMLLink({
    content,
    currentPath,
    domain,
    isLiveBlog,
    isShowBlog,
    vertical,
  });

  if (link) {
    return (
      <Head>
        <link rel="amphtml" href={link} />
      </Head>
    );
  }
  return null;
};

AMPHTMLLink.propTypes = {
  content: PropTypes.shape({ }).isRequired,
  currentPath: PropTypes.string,
  domain: PropTypes.string.isRequired,
  isLiveBlog: PropTypes.bool,
  isShowBlog: PropTypes.bool,
  vertical: PropTypes.string.isRequired,
};

AMPHTMLLink.defaultProps = {
  currentPath: '',
  isLiveBlog: false,
  isShowBlog: false,
};

const mapStateToProps = ({ router }) => ({
  domain: router.domain,
});

export default connect(mapStateToProps)(AMPHTMLLink);
